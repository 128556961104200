<template>
  <div>
    <v-scale-screen :fullScreen="false" width="1920" height="1080">
      <div class="newScreen">
        <div class="videobg">
          <div class="overlay"></div>
          <video ref="videobg" autoplay muted loop style="width: 100%; height: 100%; object-fit: cover">
            <source src="@/assets/img/bg.mp4" type="video/mp4" />
          </video>
        </div>
        <!-- 顶部 -->
        <div class="top">
          <div class="top_title">小平房村数字乡村综合治理与服务平台</div>
          <div class="top_title_people">
            <div class="top">
              <img class="left" src="@/assets/img/tel_phone.png" alt="">
              <div class="right">
                服务终端在线人数
              </div>
            </div>
            <div class="bottom">{{$store.state.onlinePoeple}}</div>
          </div>
        </div>

        <div class="cont">
          <!-- 左中 -->
          <div class="contLeft">
            <div class="nav_left inte_nav">
              <div class="flex_space flex_align" style="margin-left: 46px; width: 403px;z-index:10;">
                <div class="nav_left_item" v-for="(item, index) in leftList" :key="index" @click="showMode(item, index)">
                  <div class="nav_left_img"><img :src="item.icon" style="width: 100%; height: 100%" /></div>
                  <div class="nav_left_name">{{ item.name }}</div>
                  <div class="nav_left_num">{{ item.num || 0 }}</div>
                </div>
              </div>
                <div class="login_item">
                  <div class="login_item_img" @click="goLogin" v-if="!status">
                    <img class="left" src="@/assets/img/log.png" alt="登录" style="width: 39px;height: 39px;margin:10px 0;" />
                    登录
                  </div>
                  <div class="login_item_img" @click="goOut" v-else>
                    <img class="left" src="@/assets/img/out.png" alt="退出" style="width: 39px;height: 39px;margin:10px 0;" />退出
                  </div>
                </div>
              <transition name="fade">
                <div class="nav_left_news" v-show="show">
                  <div class="news_box flex_space flex_align">
                    <div class="flex_center_align">
                      <div class="news_rect"></div>
                      <div class="news_name">{{ content.trendsTitle }}</div>
                    </div>

                    <div class="flex_center_align">
                      <div>{{ content.date }}</div>
                      <img src="./assets/img/rarrow.png" class="news_img" />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="nsLeft">
              <div class="left-center">
                <div class="flex_space flex_center_align" style="margin-left: 24px">
                  <div class="lc_title">村情信息</div>
                  <img class="lc_right" src="./assets/img/rarrow.png" @click="goPath('villageSituation')"  v-if="canSee" />
                </div>
                <div class="line"></div>
                <div class="panel">
                  <div class="panel_top">
                    <div class="panel_title">人口动态</div>
                    <div class="p_line"></div>
                  </div>
                  <div class="panel_cun">
                    <div class="panel_cun_box">
                      <div class="flex_start">
                        <div class="circle"></div>
                        <div>
                          <div
                            class="panel_cun_name"
                            style="cursor: pointer"
                            @click="villageMode('总户数', 'familyNum')"
                          >
                            总户数
                          </div>
                          <div class="panel_cun_num">{{ total.familyNum }}</div>
                        </div>
                      </div>
                      <div class="flex_start">
                        <div class="circle"></div>
                        <div>
                          <div class="panel_cun_name" style="cursor: pointer" @click="villageMode('网格员', 'griders')">
                            网格员
                          </div>
                          <div class="panel_cun_num">{{ total.adminUserNum }}</div>
                        </div>
                      </div>
                      <div class="flex_start">
                        <div class="circle"></div>
                        <div>
                          <div
                            class="panel_cun_name"
                            style="cursor: pointer"
                            @click="villageMode('护林员', 'rangerNum')"
                          >
                            护林员
                          </div>
                          <div class="panel_cun_num">{{ total.rangerNum }}</div>
                        </div>
                      </div>
                      <div class="flex_start">
                        <div class="circle"></div>
                        <div>
                          <div
                            class="panel_cun_name"
                            style="cursor: pointer"
                            @click="villageMode('应急灭火队', 'impatientNum')"
                          >
                            应急灭火队
                          </div>
                          <div class="panel_cun_num">{{ total.impatientNum }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="ageVue">
                      <div class="echartsBg">
                        <img class="age_img" src="./assets/img/popu.png" />
                        <div class="age_num">{{ total.residentNum }}</div>
                      </div>
                      <div
                        class="circle"
                        ref="circle"
                        style="
                          width: 100%;
                          height: 100%;
                          border: 1px solid transparent;
                          -webkit-tap-highlight-color: transparent;
                          user-select: none;
                        "
                      ></div>
                      <div class="echartsLeg">
                        <div class="flex_start">
                          <div class="circle" style="border-color: #4db9a5"></div>
                          <div>
                            <div
                              class="panel_cun_name"
                              style="cursor: pointer"
                              @click="villageMode('常住人口', 'oftenNum')"
                            >
                              常住人口
                            </div>
                            <div class="panel_cun_num">{{ total.oftenNum }}</div>
                          </div>
                        </div>
                        <div class="flex_start">
                          <div class="circle" style="border-color: #f9b814"></div>
                          <div>
                            <div
                              class="panel_cun_name"
                              style="cursor: pointer"
                              @click="villageMode('外来人口', 'foreignNum')"
                            >
                              外来人口
                            </div>
                            <div class="panel_cun_num">{{ total.foreignNum }}</div>
                          </div>
                        </div>
                        <div class="flex_start">
                          <div class="circle" style="border-color: #9523cb"></div>
                          <div>
                            <div
                              class="panel_cun_name"
                              style="cursor: pointer"
                              @click="villageMode('流动人口', 'flowNum')"
                            >
                              流动人口
                            </div>
                            <div class="panel_cun_num">{{ total.flowNum }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="left-center" style="margin-top: 25px">
                <div class="flex_space flex_center_align" style="margin-left: 24px">
                  <div class="lc_title">在线课堂</div>
                  <img class="lc_right" src="./assets/img/rarrow.png" @click="goPath('classRoom')" v-if="canSee"/>
                </div>
                <div class="line"></div>
                <div class="panel">
                  <div class="panel_top" style="padding-top: 47px">
                    <div class="panel_title">阅读总量</div>
                    <div class="p_line"></div>
                  </div>
                  <div class="line_box">
                    <div ref="readline" style="width: 100%; height: 100%"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contCenter">
            <div class="nscenter">
              <!-- 地图 -->
              <div class="mapCss mapCity">
                <div ref="mapBox" style="width: 100%; height: 100%"></div>
              </div>
              <div class="center_bottom">
                <div class="left-center center_bleft">
                  <div class="flex_space flex_center_align">
                    <div class="lc_title">党建引领</div>
                    <img class="lc_right" src="./assets/img/rarrow.png" @click="goPath('newParty')" v-if="canSee" />
                  </div>
                  <div class="line"></div>
                  <div class="panel">
                    <div class="party_box">
                      <div class="party_age" style="margin-right: 15px">
                        <div class="echartsLeg" style="margin: 19px 0 23px 18px; width: 40%">
                          <div class="flex_start" v-for="(item, index) in partyNums.background" :key="index">
                            <div class="circle" :style="{ borderColor: item.color }"></div>
                            <div>
                              <div class="panel_cun_name">{{ item.name }}</div>
                              <div class="panel_cun_num">
                                <span>{{ item.value }}</span> |
                                <span>{{ item.value | formartParth(memberCount.member) }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="echartsBg" style="right: 0; width: 56%">
                          <img class="age_img" src="./assets/img/xl.png" />
                          <div class="age_num">学历结构</div>
                        </div>
                        <div ref="edstr" style="width: 178px; height: 178px"></div>
                      </div>
                      <div class="party_age">
                        <div class="echartsBg" style="width: 58%">
                          <img class="age_img" src="./assets/img/dl.png" />
                          <div class="age_num">党龄结构</div>
                        </div>
                        <div ref="partyage" style="width: 175px;height: 175px;"></div>
                        <div class="echartsLeg">
                          <div class="flex_start" v-for="(item, index) in partyNums.branchAge" :key="index">
                            <div class="circle" :style="{ borderColor: item.color }"></div>
                            <div>
                              <div class="panel_cun_name">{{ item.name }}</div>
                              <div class="panel_cun_num">
                                <span>{{ item.value }}</span> |
                                <span>{{ item.value | formartParth(memberCount.member) }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="total_box">
                        <div class="total_name">总人数</div>
                        <div class="total_num">
                          <span class="bigNum">{{ memberCount.member }}</span
                          >人
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="left-center center_nright">
                  <div class="flex_space flex_center_align">
                    <div class="lc_title">监控中心</div>
                    <img class="lc_right" src="./assets/img/rarrow.png" @click="goPath('video')" v-if="canSee" />
                  </div>
                  <div class="line"></div>
                  <div class="panel">
                    <div
                      style="
                        height: 304px;
                        margin-top: 33px;
                        margin-left: 29px;
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                      "
                    >
                      <!-- background-color: red; -->
                      <div
                        v-for="(item, index) in urls"
                        :key="index"
                        style="width: 162px; height: 111px; margin-right: 13px; margin-bottom: 15px; overflow: hidden"
                      >
                        <div :id="'video' + index"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 右 -->
          <div class="contRight">
            <div class="nav_right" @click="showPriceMode('price')">
              <el-table :data="zpajList" height="80px" ref="tab" id="boxed">
                <el-table-column prop="marketName" label="批发市场" width="100" align="center"> </el-table-column>
                <el-table-column prop="goodsName" label="品种" width="95" align="center"> </el-table-column>
                <el-table-column prop="highestPrice" label="高价" width="95" align="center"> </el-table-column>
                <el-table-column prop="minimumPrice" label="低价" width="100" align="center"> </el-table-column>
                <el-table-column prop="middlePrice" label="大宗价" width="100" align="center"> </el-table-column>
                <el-table-column prop="provinceName" label="产地" align="center"> </el-table-column>
              </el-table>
              <div class="nav_left_news">
                <div class="news_box flex_space flex_align">
                  <div class="flex_center_align">
                    <div class="news_rect"></div>
                    <div>农业农村部信息中心价格系统提供</div>
                  </div>

                  <div class="flex_center_align">
                    <div style="margin-right: 81px">{{ year }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nsright">
              <div class="left-center">
                <div class="flex_space flex_center_align">
                  <div class="lc_title">产业数据分析</div>
                  <img class="lc_right" src="./assets/img/rarrow.png" @click="goPath('industry')" v-if="canSee" />
                </div>
                <div class="line"></div>
                <div class="panel">
                  <div class="panel_top">
                    <div class="panel_title">农业数据</div>
                    <div class="p_line"></div>
                  </div>
                  <div class="right_item">
                    <div class="flex_space">
                      <div class="right_item_box">
                        <div class="item_title">总面积</div>
                        <div>
                          <span class="muNum">32.34</span>
                          <span class="mu">平方公里</span>
                        </div>
                      </div>
                      <div class="right_item_box">
                        <div class="item_title">总耕地</div>
                        <div>
                          <span class="muNum">5303</span>
                          <span class="mu">亩</span>
                        </div>
                      </div>
                    </div>
                    <div class="flex_space">
                      <div class="right_item_box">
                        <div class="item_title">有林地</div>
                        <div>
                          <span class="muNum">26512</span>
                          <span class="mu">亩</span>
                        </div>
                      </div>
                      <div class="right_item_box">
                        <div class="item_title">其他</div>
                        <div>
                          <span class="muNum">16695</span>
                          <span class="mu">亩</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel_top">
                    <div class="panel_title">工业数据</div>
                    <div class="p_line"></div>
                  </div>
                  <div ref="industry" style="width: 90%; height: 200px; margin: 0 auto"></div>

                  <div class="panel_top" style="padding-top: 17px">
                    <div class="panel_title">服务业企业</div>
                    <div class="p_line"></div>
                  </div>
                  <div ref="saleLine" style="width: 90%; height: 146px; margin: 0 auto"></div>
                  <div ref="people" style="width: 90%; height: 146px; margin: 0 auto"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <newMode ref="newMode"></newMode>

      <!-- 村委班子 -->
      <num-mode ref="numMode"></num-mode>
      <h-login ref="loginmode" @login="loginForm"></h-login>

    </v-scale-screen>
  </div>
</template>
<script>
import newMode from '@/components/newMode.vue'
import numMode from '@/components/numModeNew.vue'
import { mapOption, barOptions, newlineOption, newMapOption } from './utils/options.js'
import { mapdata, geoCoordMap, coordinates } from './utils/mapCenterPoint'
import { vipCarList, vipCarDateList, getIndexStatistics, postLogin, getDangIndex, getRead, getTokenVideo, selectMajorCamera, articleInfoList, getBankList, getLineClass, goodsInfoList, searchReadNums, searchNewsList, clearReadNums } from '@/api/api.js'
import EZUIKit from 'ezuikit-js'
const mapData = require('./utils/newcun.json')
import * as echarts from 'echarts'
import { clearInterval, setTimeout } from 'timers'

import hLogin from '@/components/login.vue'
var player = ''
export default {
  components: {
    newMode,
    numMode,
    hLogin
  },
  data() {
    return {
      finalId: null,
      leftList: [
        {
          name: '民众心声',
          icon: require('./assets/img/imz.png'),
          type: '3',
          ptype: 'voxPop',
          num: 0
        },
        {
          name: '全民广场',
          icon: require('./assets/img/iqm.png'),
          type: '2',
          ptype: 'nationalSquare',
          num: 0
        },
        {
          name: '在线课堂',
          icon: require('./assets/img/isk.png'),
          type: '1',
          ptype: 'readBook',
          num: 0
        },
        {
          name: '道德银行',
          icon: require('./assets/img/ibank.png'),
          type: '4',
          ptype: 'releaseReq',
          num: 0
        },
        {
          name: '贵客到访',
          icon: require('./assets/img/car.png'),
          type: '5',
          ptype: 'monitor',
          num: 0
        }
      ],
      zpajList: [],
      total: {
        familyNum: 0,
        residentNum: 0,
        adminUserNum: 0,
        oftenNum: 0,
        foreignNum: 0,
        flowNum: 0,
        rangerNum: 0,
        impatientNum: 0
      },
      partyNums: {},
      memberCount: {},
      urls: [],
      pingfangziaccessToke: '',
      rolltimer: '',
      show: false,
      content: {},
      nerwstimer: '',
      year: '',
      baseForm: {},
      //登录按钮显隐
      status: false,
      //能否查看详情
      canSee: false
    }
  },
  filters: {
    formartParth(data, total) {
      let num = (data / total * 100).toFixed(2)
      return num + '%'
    }
  },
  methods: {
    goHome() {
      this.$router.replace('/')
    }, // 获取Token
    getTokenpingfangzi() {
      let datapfz = {
        appKey: 'a145fefba06a4bfb8ac283db040085a1',
        appSecret: '05e998d7e5044c346bedbb501c8747a7'
      }
      getTokenVideo(datapfz).then(res => {
        if (res.code == 200) {
          this.pingfangziaccessToke = res.data.accessToken
          this.getMajor()
        }
      })
    },
    getMajor() {
      // this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
      selectMajorCamera().then(res => {
        this.urls = res.model
        console.log(res.model)

        this.$nextTick(() => {
          res.model.forEach((item, index) => {
            setTimeout(() => {
              let id = 'video' + index
              this.initEzuikMajor(item.cameraUrl, id, index + 1)
            }, 0)
          })
        })
      })
    },
    initEzuikMajor(url, id, index) {
      console.log('url', url)
      player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: id,
        accessToken: this.pingfangziaccessToke,
        url: url,
        template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        width: '162px',
        height: '111px'
      })
    },
    initMap() {
      var convertData = function(data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value * 2)
            })
          }
        }
        return res
      }

      echarts.dispose(this.$refs.mapBox)
      var myChart = echarts.init(this.$refs.mapBox)

      var option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0, 153, 255, 0.82)',
          borderColor: '#FFFFCC',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          extraCssText: 'z-index:100',
          formatter: function(params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = ''
            var name = params.name
            // console.log(name);
            res = "<span style='color:#fff;'>" + name + '</span>'
            return res
          }
        },
        geo: {
          map: 'xiaopingfang',
          aspectScale: 1, //长宽比
          zoom: 1.15,
          roam: false,
          itemStyle: {
            normal: {
              // areaColor: '#00FFFF', // 地图颜色
              // borderWidth: 8, // 边框的宽度
              shadowColor: '#0099ff', //  阴影颜色
              borderColor: '#00FFFF', // 边框颜色
              shadowBlur: 12, // 阴影的模糊大小
              shadowOffsetX: 0, //阴影水平方向上的偏移距离
              shadowOffsetY: 15, // 阴影垂直方向上的偏移距离
              areaColor: {
                x: 1000,
                y: 1000,
                x2: 1000,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#69c5d8' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#126caf' // 50% 处的颜色
                  }
                ],
                global: true // 缺省为 false
              },
              opacity: 1
            },
            emphasis: {
              areaColor: '#2AB8FF',
              borderWidth: 0,
              color: 'green',
              label: {
                show: false
              }
            }
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 0.8, //最小的缩放值
            max: 3 //最大的缩放值
          }
        },
        series: [
          {
            type: 'map',
            map: 'xiaopingfang', //使用
            roam: false,
            aspectScale: 1,
            // geoIndex: 0,
            zoom: 1.15,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },

            itemStyle: {
              normal: {
                areaColor: '#334fa3', //正常背景颜色
                // borderColor: '#4F8DBB',
                borderColor: '#8cD2',
                borderWidth: 1.5
              },
              emphasis: {
                areaColor: '#2AB8FF', //选中颜色
                borderWidth: 0,
                color: 'green',
                show: false
              }
            },
            scaleLimit: {
              //所属组件的z分层，z值小的图形会被z值大的图形覆盖
              min: 0.8, //最小的缩放值
              max: 3 //最大的缩放值
            }
          },
          {
            name: 'city',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            geoIndex: 0,
            data: convertData(mapdata),
            symbolSize: 5,
            showEffectOn: 'render',
            //涟漪特效相关配置
            rippleEffect: {
              brushType: 'stroke',
              period: 1,
              scale: 2 //动画中波纹的最大缩放比例。
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
                color: '#F5F5F5',
                fontWeight: 'bold',
                fontSize: '12'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                shadowColor: '#0098d9', //  阴影颜色
                shadowBlur: 30,
                shadowOffsetX: 0, //阴影水平方向上的偏移距离
                shadowOffsetY: 20, // 阴影垂直方向上的偏移距离
                emphasis: {
                  areaColor: '#2B91B7'
                }
              }
            },
            zlevel: 1
          },
          {
            type: 'lines',
            polyline: true,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.3,
              symbolSize: 6
            },
            lineStyle: {
              width: 1.5,
              color: '#7ee0ff',
              opacity: 0.5
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: coordinates,
            zlevel: 10
          }
        ]
      }

      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, {
      //   renderer: 'svg',
      // })
      // map.clear()
      myChart.setOption(option)
    },
    initChart() {
      // 地图绘制
      this.initMap()

      barOptions.xAxis.data = ['泰达砖厂', '小平房铁矿', '万昆矿业', '永山车队', '秀源果业']
      barOptions.yAxis.axisLine.lineStyle.color = '#fff'
      // barOptions.yAxis.axisLabel.textStyle.fontSize = 7
      barOptions.grid.x = 40
      barOptions.grid.bottom = 40
      barOptions.yAxis.name = '万元'
      barOptions.series[0] = {
        type: 'bar',
        showBackground: false,
        barMaxWidth: 16, // 每一个都要设置
        barMinWidth: 5, // 每一个都要设置
        itemStyle: {
          barBorderRadius: [6, 6, 6, 6],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#FFFFFF'
            },
            {
              offset: 1,
              color: '#0063B8'
            }
          ]),
          emphasis: {
            label: {
              show: true,
              position: 'top',
              color: '#fff'
            }
          }
        },
        data: ['2000', '3000', '8000', '150', '1500']
      }
      let chart = echarts.init(this.$refs.industry, null, {
        renderer: 'svg'
      })
      chart.clear()
      chart.setOption(barOptions)

      newlineOption.grid = {
        left: '10%',
        right: '4%',
        bottom: '4%',
        containLabel: true
      }
      newlineOption.xAxis.data = ['4月', '5月', '6月', '7月', '8月', '9月']
      newlineOption.yAxis.name = '（销售额/万元）'
      newlineOption.series[0] = {
        data: ['2', '3.4', '2.5', '4.6', '3.45', '3.31'],
        type: 'line',
        smooth: 0.6,
        symbol: 'rect',
        symbolSize: [4, 2.41],
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              // 渐变颜色
              {
                offset: 0,
                color: 'rgba(255, 245, 0, 0.2)'
              },
              {
                offset: 1,
                color: 'rgba(255, 245, 0, 0)'
              }
            ],
            global: false
          }
        },
        itemStyle: {
          normal: {
            color: '#FFFFFF', //改变折线点的颜色
            lineStyle: {
              color: '#efe704' //改变折线颜色
            }
          }
        }
      }
      let linenew = echarts.init(this.$refs.saleLine, null, {
        renderer: 'svg'
      })
      linenew.clear()
      linenew.setOption(newlineOption)

      newlineOption.series[0] = {
        data: ['2.1', '3.2', '3', '5.2', '4.45', '3.91'],
        type: 'line',
        smooth: 0.6,
        symbol: 'rect',
        symbolSize: [4, 2.41],
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              // 渐变颜色
              {
                offset: 0,
                color: 'rgba(0, 133, 255, 0.2)'
              },
              {
                offset: 1,
                color: 'rgba(255, 245, 0, 0)'
              }
            ],
            global: false
          }
        },
        itemStyle: {
          normal: {
            color: '#FFFFFF', //改变折线点的颜色
            lineStyle: {
              color: '#0085FF' //改变折线颜色
            }
          }
        }
      }
      newlineOption.xAxis.data = ['4月', '5月', '6月', '7月', '8月', '9月']
      newlineOption.yAxis.name = '（客流量/万人）'
      let linen = echarts.init(this.$refs.people, null, {
        renderer: 'svg'
      })
      linen.clear()
      linen.setOption(newlineOption)
    },
    drowCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#F9B814', '#9523CB'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['常住人口', '外来人口', '流动人口']
        },
        series: [
          {
            name: '人口',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [
              {
                value: data.oftenNum,
                name: '常住人口'
              },
              {
                value: data.foreignNum,
                name: '外来人口'
              },
              {
                value: data.flowNum,
                name: '流动人口'
              }
            ]
          }
        ]
      }
      let cricle = echarts.init(this.$refs.circle, null, {
        renderer: 'svg'
      })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    getgrids() {
      getIndexStatistics().then(res => {
        if (res.code == 0) {
          this.total = res.items[0]
          setTimeout(() => {
            this.drowCircle(res.items[0])
          }, 1000)
        }
      })
    },
    //登录
    submitForm(formName) {
      let that = this
      let form = {
        username: 'xiaopingfang',
        password: '123456'
      }
      postLogin(form)
        .then(res => {
          if (res.code == '0') {
            // sessionStorage.clear()
            // sessionStorage.setItem('token', res.model.tokenValue)
            // sessionStorage.setItem('userInfo', JSON.stringify(res.model))
            this.baseForm = res.model
            that.$nextTick(() => {
              this.getgrids()
              that.searchDetails(res.model.regionId)
            })
          } else {
            that.$message.error(res.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchDetails(regionId) {
      getDangIndex({
        villageId: regionId
      }).then(res => {
        if (res.code == 0) {
          let arr = ['#4DB9A5', '#5F8CFF', '#6B1F9C', '#4D65B9', '#F9B814', '#4D65B9']
          if (res.model.background) {
            res.model.background.map((item, index) => {
              item.color = arr[index]
            })
          }
          if (res.model.branchAge) {
            res.model.branchAge.map((item, index) => {
              item.color = arr[index]
            })
          }
          this.partyNums = res.model
          this.memberCount = res.model?.memberCount || {}
          this.$nextTick(() => {
            this.drowFunction(res.model)
          })
        }
      })
    },
    //绘制党建部分环形图
    drowFunction(data) {
      let edLegend = []
      // 学历结构
      let edOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#5F8CFF', '#6B1F9C', '#4D65B9', '#F9B814', '#4D65B9'],
        legend: {
          show: false
        },
        series: [
          {
            name: '学历结构',
            type: 'pie',
            radius: ['85%', '100%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: data.background
          }
        ]
      }
      let edstr = echarts.init(this.$refs.edstr, null, {
        renderer: 'svg'
      })
      edstr.clear()
      edstr.setOption(edOption)

      //  let edLegend = []
      // 学历结构
      let partyOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#5F8CFF', '#6B1F9C', '#4D65B9', '#F9B814', '#4D65B9'],
        legend: {
          show: false
        },
        series: [
          {
            name: '党龄统计',
            type: 'pie',
            radius: ['85%', '100%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: data.branchAge
          }
        ]
      }
      let party = echarts.init(this.$refs.partyage, null, {
        renderer: 'svg'
      })
      party.clear()
      party.setOption(partyOption)
    },
    searchRead() {
      getRead().then(res => {
        if (res.code == '0') {
          let xarr = []
          let yarr = []
          res.model.map(i => {
            xarr.push(i.monthValue + '月')
            yarr.push(i.browseNums)
          })

          let lioption = {
            title: {
              text: ''
            },
            grid: {
              top: '10',
              left: '30',
              bottom: '20'
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: xarr,
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#3a416b'
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#a1b3e5',
                  fontSize: 8
                }
              }
            },
            yAxis: {
              type: 'value',
              labelLine: {
                show: false
              },
              splitLine: {
                show: false
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#a1b3e5'
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#a1b3e5',
                  fontSize: 8
                }
              }
            },
            series: [
              {
                data: yarr,
                type: 'line',
                smooth: 0.6,
                symbol: 'none',
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      // 渐变颜色
                      {
                        offset: 0,
                        color: '#334fa3'
                      },
                      {
                        offset: 1,
                        color: 'rgba(51,79,163, 0)'
                      }
                    ],
                    global: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: '#4c78ed', //改变折线点的颜色
                    lineStyle: {
                      color: '#4c78ed' //改变折线颜色
                    }
                  }
                }
              }
            ]
          }

          let readline = echarts.init(this.$refs.readline, null, {
            renderer: 'svg'
          })
          readline.clear()
          readline.setOption(lioption)
        }
      })
    },
    setautoRoll(stop) {
      if (stop && this.rolltimer) {
        window.clearInterval(this.rolltimer)
        return
      }
      const table = this.$refs.tab
      // 拿到表格中承载数据的div元素
      const divData = table.bodyWrapper
      console.log(table)

      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      if (stop) {
        //再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
        window.clearInterval(this.rolltimer)
      } else {
        this.rolltimer = setInterval(() => {
          // 元素自增距离顶部1像素
          divData.scrollTop += 1
          // debugger
          if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
            divData.scrollTop = 0
          }
        }, 100) // 滚动速度
        console.log(this.rolltimer)
      }
    },
    showMode(item, index) {
      if (!this.canSee) {
        this.$message.error('请先登录')
        return
      }
      if (['3', '2'].includes(item.type)) {
        this.searchArticles(item.type, item, index)
      } else if (item.type == '4') {
        // 道德银行
        this.searchBnakList(item, index)
      } else if (item.type == '1') {
        getLineClass().then(res => {
          if (res.code == '0') {
            if (!res.model.length) {
              this.$message.error('暂无数据')
              return
            }
            let newData = {
              form: item,
              list: res.model
            }
            this.$refs.newMode.show = true
            this.$refs.newMode.initData(newData)
          }
        })
      } else if (item.type == '5') {
        this.vipCarList(item, index)
      }
    },

    vipCarList(item, index) {
      // vipCarList().then(res => {
      //   if (res.code == '0') {
      //     if (!res.model.length) {
      //       this.$message.error('暂无数据')
      //       return
      //     }
      //     let newData = {
      //       form: item,
      //       list: res.model
      //     }
      //     this.$refs.newMode.show = true
      //     this.$refs.newMode.initData(newData)
      //   }
      // })
      // return
      vipCarDateList().then(res => {
        if (res.code == '0') {
          console.log('res', res)
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchArticles(type, data, index) {
      let params = {
        townId: 6281,
        page: 1,
        rows: 100000,
        articleType: 1,
        articleSubtype: type
      }
      articleInfoList(params).then(res => {
        if (res.code == '0') {
          this.articleList = res.items
          if (!res.items.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: data,
            articleList: res.items
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchBnakList(item, index) {
      getBankList().then(res => {
        if (res.code == '0') {
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchGoods() {
      goodsInfoList({
        goodsType: 2,
        page: 1,
        rows: 10000
      }).then(res => {
        if (res.code == '0') {
          this.zpajList = res.items
          //滚动
          this.setautoRoll()
        }
      })
    },
    showMessage(content) {
      this.content = content
      this.show = true
      if (content) {
        // let item = this.leftList.filter(item => {
        //   return item.type == content.type
        // })[0]
        // let index = this.leftList.findIndex(item => item.type == content.type)

        setTimeout(() => {
          this.getReadnums()
          // item.num = item.num + 1
          // this.$set(this.leftList, index, item)
          this.content = {}
          this.show = false
        }, 5000) // 5秒后隐藏消息
      }
    },
    searchNews() {
      this.getNews()
      return
      let index = 0
      let arr = [
        {
          title: '小平房村  2组  王祥发布了一条消息',
          date: '刚刚',
          type: '1'
        },
        {
          title: '小平房村  1组  徐翰立正在看书《农业种植栽培大全》',
          date: '3分钟前',
          type: '3'
        },
        {
          title: '小平房村  1组  刘怀隆立正在看书《跟着季节种菜》',
          date: '1分钟前',
          type: '3'
        },
        {
          title: '小平房村  4组  贡雅丽发布了一条消息',
          date: '10分钟前',
          type: '1'
        },
        {
          title: '小平房村  3组  姚玉发布道德银行积分申报获得了200积分',
          date: '5分钟前',
          type: '2'
        },
        {
          title: '小平房村  6组  王利发布道德银行积分申报获得了400积分',
          date: '刚刚',
          type: '2'
        },
        {
          title: '小平房村  1组  王建立正在看书《无公害棚室栽培技术大全》',
          date: '刚刚',
          type: '2'
        },
        {
          title: '小平房村  4组  于长波发布了一条消息',
          date: '1分钟前',
          type: '1'
        },
        {
          title: '小平房村  5组  尹德鹏发布了一条消息',
          date: '1分钟前',
          type: '1'
        },
        {
          title: '官方消息：黄色暴雨预警',
          date: '3分钟前',
          type: '3'
        },
        {
          title: '小平房村  1组  孙贤坤正在看书《农业种植栽培大全》',
          date: '1分钟前',
          type: '3'
        },
        {
          title: '小平房村  1组  王鸿林立正在看书《无公害棚室栽培技术大全》',
          date: '5分钟前',
          type: '3'
        },
        {
          title: '小平房村  4组  张家宾发布了一条消息',
          date: '刚刚',
          type: '1'
        },
        {
          title: '小平房村  3组  唐林茂发布道德银行积分申报获得了200积分',
          date: '1分钟前',
          type: '2'
        },
        {
          title: '小平房村  6组  王延宇布道德银行积分申报获得了400积分',
          date: '1分钟前',
          type: '2'
        }
      ]

      this.nerwstimer = setInterval(() => {
        this.showMessage(arr[index])
        if (index > arr.length - 1) {
          index = 0
          return
        }
        index++
      }, 10000) // 滚动速度
    },
    goPath(name) {
      this.$router.push({
        name: name
      })
    },
    villageMode(name, type) {
      console.log('=-------->', name, type)
      console.log(name)
      console.log(type)
      console.log('================>', this.baseForm)
      let item = { type: type, name: name, showType: '', regionId: this.baseForm.regionId }
      if (['cunMembers', 'rangerNum', 'forestPreNum', 'impatientNum'].includes(type)) {
        item.showType = 'peopleImg'
        // 'residentNum',
      } else if (['oftenNum', 'foreignNum', 'flowNum'].includes(type)) {
        item.showType = 'description'
      } else if (['familyNum'].includes(type)) {
        item.showType = 'tableList'
      } else if (['griders'].includes(type)) {
        item.showType = 'gridList'
      }
      console.log(('item==========>', item))
      this.$refs.numMode.initData(item)
    },
    getNews() {
      this.nerwstimer = setInterval(() => {
        searchNewsList({ finalId: this.finalId }).then(res => {
          if (res.code == '0' && res.model.length >= 0) {
            //更新参数id
            if (res.model.length > 0) {
              this.finalId = res.model[res.model.length - 1].id
            }
            res.model.map(i => {
              setTimeout(() => {
                this.showMessage(i)
              }, 10050)
            })
          } else {
            this.finalId = res.model
          }
        })
      }, 10000)
    },
    getReadnums() {
      searchReadNums().then(res => {
        if (res.code == '0') {
          //终端在线人数
          this.$store.state.onlinePoeple = res.model.onlineNums
          // this.$store.commit('onLine', res.model.onlineNums)
          this.leftList.map(i => {
            if (i.ptype == 'voxPop') i.num = res.model.voxPopNum
            if (i.ptype == 'nationalSquare') i.num = res.model.nationalSquareNum
            if (i.ptype == 'readBook') i.num = res.model.onlineReadNum
            if (i.ptype == 'releaseReq') i.num = res.model.releaseReqNum
            // 贵客到访
            if (i.ptype == 'monitor') i.num = res.model.monitorNum
          })
        }
      })
    },
    showPriceMode(type) {
      if (!this.canSee) {
        this.$message.error('请先登录')
        return
      }
      let newData = {
        form: {
          type: '',
          ptype: 'price',
          name: '市场价格'
        }
      }
      this.$refs.newMode.show = true
      this.$refs.newMode.initData(newData)
    },
    onlinePeople() {
      // console.log('------------------------------------');
      this.$store.commit('onLine', 10)
    },

    goLogin() {
      this.$refs.loginmode.show = true
    },
    //退出
    goOut() {
      sessionStorage.clear()
      this.status = false
      this.canSee = false
    }
  },

  mounted() {
    console.log('isFormLogin', sessionStorage.getItem('isFormLogin'))

    if (sessionStorage.getItem('isFormLogin')) {
      this.status = true
    }
    if (sessionStorage.getItem('isPoster')) {
      this.canSee = true
    }
    this.submitForm()
    this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
    this.year = this.$moment().format('YYYY-MM-DD')
    //摄像头查询
    this.getTokenpingfangzi()
    //查询价格
    this.searchGoods()
    this.$nextTick(() => {
      //静态数据echarts
      this.initChart()
      // 阅读量
      this.searchRead()
      this.getReadnums()
    })
    //消息推送
    this.getNews()

    //终端在线人数
    // this.onlinePeople()
  },
  destroyed() {
    // if (this.rolltimer) window.clearInterval(this.rolltimer)
    this.setautoRoll(true)
    if (this.nerwstimer) window.clearInterval(this.nerwstimer)
  }
}
</script>
<style lang="scss" scope>
@import '@/common/css/inter';
</style>
